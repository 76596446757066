import "./App.css";
import { useEffect, useState ,  lazy, Suspense  } from "react";
import Header from "./component/layout/Header/Header.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import WebFont from "webfontloader";
import React from "react"; 
import axios from "axios";

import store from "./store";
import { loadUser } from "./actions/userAction";
import ProtectedRoute from "./component/Route/ProtectedRoute";

import ConfirmOrder from "./component/Cart/ConfirmOrder";

import ScrollToTop from "./ScrollToTop";
import Loader from "./component/layout/Loader/Loader";
import Career from "./component/layout/Footer/Career";
import Pottedplants from "./component/Home/Pottedplants";
import Indoorplants from "./component/Home/Indoorplants";
import Terracesets from "./component/Home/Terracesets";
import Pottingsoil from "./component/Home/Pottingsoil";
import UpdateBlog from "./component/Admin/UpdateBlog";

const Forest = lazy(() => import('./component/layout/Forest/Forest'));
// const Blog = lazy(() => import('./component/Blog/Blog'));
const Privacy = lazy(() => import('./component/layout/Footer/Privacy'));
const FAQ = lazy(() => import('./component/layout/Footer/Faquestion'));
const Returns = lazy(() => import('./component/layout/Footer/Returns'));
const Shippingdetails = lazy(() => import('./component/layout/Footer/Shippingdetails'));
const Plantcaretips = lazy(() => import('./component/layout/Footer/Plantcaretips'));
const Productlist = lazy(() => import('./component/layout/Productlist/Productlist'));


const Dashboard = lazy(() => import('./component/Admin/Dashboard.js'));
const ProductList = lazy(() => import('./component/Admin/ProductList.js'));
// blo list
const BlogList  = lazy(() => import('./component/Admin/BlogList.jsx'));

const NewProduct = lazy(() => import('./component/Admin/NewProduct'));
//new blog
const NewBlog = lazy(() => import('./component/Admin/NewBlog.js'));

const OrderList = lazy(() => import('./component/Admin/OrderList'));
const ProcessOrder = lazy(() => import('./component/Admin/ProcessOrder'));
const UpdateProduct = lazy(() => import('./component/Admin/UpdateProduct'));
// const UpdateBlog = lazy(() => import('./component/Admin/UpdateBlog'));

const UsersList = lazy(() => import('./component/Admin/UsersList'));
const UpdateUser = lazy(() => import('./component/Admin/UpdateUser'));
const ProductReviews = lazy(() => import('./component/Admin/ProductReviews'));
const Profile = lazy(() => import('./component/User/Profile'));
const ForgotPassword = lazy(() => import('./component/User/ForgotPassword'));
const ResetPassword = lazy(() => import('./component/User/ResetPassword'));
const Productmob = lazy(() => import('./component/layout/Productmob/Productmob'));
const Terracegarden = lazy(() => import('./component/Home/Terracegarden'));
const Footer = lazy(() => import('./component/layout/Footer/Footer'));
const ProductDetails = lazy(() => import('./component/Product/ProductDetails'));
// blog details
const BlogDetails = lazy(() => import('./component/Blog/BlogDetails'));

const Products = lazy(() => import('./component/Product/Products'));
// blogs
const Blogs = lazy(() => import('./component/Blog/Blogs'));

const Tempblog = lazy(() => import('./component/Blog/Tempblog'));
const Cart = lazy(() => import("./component/Cart/Cart"));
const Shipping = lazy(() => import("./component/Cart/Shipping"));
// const ConfirmOrder = lazy(() => import("./component/Cart/ConfirmOrder"));
const LoginSignUp = lazy(() => import("./component/User/LoginSignUp"));
const UpdateProfile = lazy(() => import("./component/User/UpdateProfile"));
const UpdatePassword = lazy(() => import("./component/User/UpdatePassword"));
const OrderSuccess = lazy(() => import("./component/Cart/OrderSuccess"));
const Payment = lazy(() => import("./component/Cart/Payment"));
const MyOrders = lazy(() => import("./component/Order/MyOrders"));
const OrderDetails = lazy(() => import("./component/Order/OrderDetails"));
const Search = lazy(() => import("./component/Product/Search"));

// new adde for blog pages
const Tempblogpage = lazy(() => import("./component/Blogsofgarden/Tempblogpage"));
const Blogpage2 = lazy(() => import("./component/Blogsofgarden/Blogpage2"));
const Blogpage3 = lazy(() => import("./component/Blogsofgarden/Blogpage3"));
const Blogpage4 = lazy(() => import("./component/Blogsofgarden/Blogpage4"));
const Blogpage5 = lazy(() => import("./component/Blogsofgarden/Blogpage5"));
const Blogpage6 = lazy(() => import("./component/Blogsofgarden/Blogpage6"));

function  App() {
  // const { isAuthenticated, user } = useSelector((state) => state.user);
  const [stripeApiKey, setStripeApiKey] = useState("");

  async function getStripeApiKey() {
    const { data } = await axios.get("/api/v1/stripeapikey");

    setStripeApiKey(data.stripeApiKey);
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto", "Droid Sans", "Chilanka"],
      },
    });

    store.dispatch(loadUser());

    getStripeApiKey();
  }, []);

  // window.addEventListener("contextmenu", (e) => e.preventDefault()); // to prevent inspecting right click

  return (
    <Router>
{/* {window.location.pathname === '/login' || window.location.pathname === '/account' ? null :
<Header />  } */}
   <ScrollToTop/>
   <Header/> 

    
        
      <Switch>
        {/* <Route exact path="/" component={Terracegarden}/> */}
        <Route exact path="/">
        <Suspense fallback={<Loader/>}>
        <Terracegarden/>
        </Suspense>
        </Route>

        <ProtectedRoute exact path="/order/confirm" component={ConfirmOrder} />

  <Suspense fallback={<Loader/>}>
        <Route exact path="/product/:id" component={ProductDetails} />
        <Route exact path="/blog/:id" component={BlogDetails} />
        <Route exact path="/blogsofgardening" component={Tempblog} />
        <Route exact path="/pottedplants" component={Pottedplants}/>
        <Route exact path="/indoorplants" component={Indoorplants}/>
        <Route exact path="/terracegarden" component={Terracesets}/>
        <Route exact path="/pots&pottingsoil" component={Pottingsoil}/>
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/login" component={LoginSignUp} />
        <ProtectedRoute exact path="/account" component={Profile} />
        <ProtectedRoute exact path="/shipping" component={Shipping} />
        <ProtectedRoute exact path="/order/:id" component={OrderDetails} />
        {/* <ProtectedRoute exact path="/order/confirm" component={ConfirmOrder} />  */}
         
         <ProtectedRoute exact path="/me/update" component={UpdateProfile} />
         <ProtectedRoute exact path="/password/update" component={UpdatePassword} />
         <ProtectedRoute exact path="/process/payment" component={Payment}/>
         <ProtectedRoute exact path="/success" component={OrderSuccess}/> 
         <ProtectedRoute exact path="/orders" component={MyOrders}/>

         {/* for blogpages */}
         <Route exact path="/importance-of-terracegarden-in-cities" component={Tempblogpage} />
         <Route exact path="/A-Guide-to-Creating-Your-Terrace-Garden" component={Blogpage2} />
         <Route exact path="/Must-have-plant-on-terracegarden-moringa" component={Blogpage3} />
         <Route exact path="/size-of-plants-to-Enhance-Oxygen-Supply-in-Your-Terrace-Garden" component={Blogpage4} />
         <Route exact path="/Top-5-Plants-for-Your-Indian-Terrace-Garden: Anjeer-Guava-Nimboo-and-More" component={Blogpage5} />
         <Route exact path="/Crafting-the-Perfect-Potting-Mix-for-Terrace-Gardening" component={Blogpage6} />
        
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/dashboard"
          component={Dashboard} />

       <ProtectedRoute
          exact
          path="/admin/users"
          isAdmin={true}
          component={UsersList}
        />
        <ProtectedRoute
          exact
          path="/admin/user/:id"
          isAdmin={true}
          component={UpdateUser}
        />
         <ProtectedRoute
          exact
          path="/admin/products"
          isAdmin={true}
          component={ProductList}
        />
   <ProtectedRoute
          exact
          path="/admin/blogs"
          isAdmin={true}
          component={BlogList}
        />

        <ProtectedRoute
          exact
          path="/admin/product"
          isAdmin={true}
          component={NewProduct}
        />
        <ProtectedRoute
          exact
          path="/admin/blog"
          isAdmin={true}
          component={NewBlog}
        />
      
        <ProtectedRoute
          exact
          path="/admin/product/:id"
          isAdmin={true}
          component={UpdateProduct}
        />
        <ProtectedRoute
          exact
          path="/admin/blog/:id"
          isAdmin={true}
          component={UpdateBlog}
        />
       
        <ProtectedRoute
          exact
          path="/admin/orders"
          isAdmin={true}
          component={OrderList}
        />
         
        <ProtectedRoute
          exact
          path="/admin/order/:id"
          isAdmin={true}
          component={ProcessOrder}
        />
           <ProtectedRoute
          exact
          path="/admin/reviews"
          isAdmin={true}
          component={ProductReviews}
        />
        <Route path="/products/:keyword?" component={Products}/>
        <Route path="/blogs/:keyword?" component={Blogs} />

        <Route exact path="/search" component={Search} />
        <Route exact path="/forest">  <Forest/>  </Route>
        
        <Route exact path="/Privacy" component={Privacy} />
        <Route exact path="/Plantcaretips" component={Plantcaretips} />
        {/* <Route exact path="/Blog" component={Blog} /> */}
        <Route exact path="/FAQ" component={FAQ} /> 
        <Route exact path="/Returns" component={Returns} />
        <Route exact path="/Shippingdetails" component={Shippingdetails}/> 
        <Route exact path="/Career" component={Career} />
        <Route exact path="/password/forgot" component={ForgotPassword}/>
        <Route exact path="/password/reset/:token" component={ResetPassword}/> 
        <Route
          component={
            window.location.pathname !== "/process/payment" &&  null 
          }
        />
</Suspense>
  
        {/* <Route exact path="/terracegarden" component={Terracegarden} /> */}
        <Route exact path="/terracegarden">
        <Suspense fallback={<Loader/>}>
        <Terracegarden/>
        </Suspense>
        </Route>

        {/* <Route exact path="/productlist" component={Productlist} /> */}
        <Route exact path="/productlist">
        <Suspense fallback={<div>Loading...</div>}>
        <Productlist/>
        </Suspense>
        </Route>
        
         {/* <Route exact path="/productmob" component={Productmob} /> */}
         <Route exact path="/productmob">
        <Suspense fallback={<div>Loading...</div>}>
        <Productmob/>
        </Suspense>
        </Route>

       

      </Switch>
    { window.location.pathname === '/login' || window.location.pathname === '/contact'|| window.location.pathname === '/admin/product' || window.location.pathname === '/about'|| window.location.pathname === '/account' || window.location.pathname === '/contact'  ? null : 
      <Suspense fallback={<div>Loading...</div>}> <Footer/> </Suspense>}

    </Router>
  );
}

export default App;
