import React from 'react'
import './Announcement.css'

const Announcement = () => {
  return (
    <div id="siteAnnouncement">
  <div className="containerbar">
    <p>FREE Shipping + Cash on delivery  </p>
  
  </div>
</div>
  )
}

export default Announcement