import React, { Fragment, useEffect, useState, Suspense, lazy } from "react";
import { Rating } from "@material-ui/lab";
import { Link } from "react-router-dom";
import "./Home.css";
import MetaData from "../layout/MetaData";
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader"; 
import { useAlert } from "react-alert";
import Bestseller from "./Bestseller";
import '../Product/ProductDetails.css'


const divStyle = {

  color: 'Black',
  fontWeight: '600',
};

const ratingValue = 5;

const ratingReadOnly = true;
const ratingSize = "small";

const ratingComponent = (
  <Rating name="read-only" value={ratingValue} readOnly={ratingReadOnly} size={ratingSize} />
);

export const Shoppingbtns = ({ terraceLink, terraceText, shopAllLink, shopAllText }) => {
  return (
    <div style={{ display: 'flex' }}>
      <Link to={terraceLink}>
        <button type="button" className="custom-btn btn-15">
          {terraceText}
        </button>
      </Link>
      <Link to={shopAllLink}>
        <button className="btnadde">{shopAllText}</button>
      </Link>
    </div>
  );
};

const button1 =   (
  <button className="btnadd buttonfont"
                    
  >
  View Product
  </button>
);


const Terracesets = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct());
  }, [dispatch, error, alert]);

  // new addde
  const images = [
    'https://res.cloudinary.com/dc5luxn64/image/upload/v1687094147/Backgrounds/Untitled_design_11_xlvpga.png',
  
  ];
    const laptopImage = [
      'https://res.cloudinary.com/dc5luxn64/image/upload/v1686985868/Backgrounds/terrace_garden_chair_person_ggdadg.webp',
      
  ];



  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length );
    }, 3000);
    
    return () => {
      clearInterval(interval);
    };
  },
   [images.length ]);

// to here
 const fruitsmall1 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688464469/products/kzsbxdlxigknlznxitma.webp'
 const fruitsmall2 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688466274/products/mejnayv7nfhcanuuqsh5.webp'
 const fruitsmall3 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688464599/products/ad4awns5tnvekbte7v1i.webp'
 const fruitsmall4 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688464901/products/p6mpsstz3qdrsvouf0ba.webp'


 const line2img1 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1689341400/products/cebq8xrmubvnki14kwpa.webp'
 const line2img2 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1688464735/products/ooqfypvmrgilukazdn8y.webp'
 const line2img3 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1685533995/products/xg8vkpu1azodus2qjus9.webp'
 const line2img4 = 'https://res.cloudinary.com/dc5luxn64/image/upload/v1689340705/products/ocpyzpvk60bf9zknsctx.webp'
 

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="homepage">
          <MetaData title="Terrace Garden" />

<div className="app__bg3NW app__headerNW app__wrapperNW section__paddingNW" id="home">
  <picture>
    <source
      media="(min-width: 768px)"
      srcSet={`${laptopImage[currentImageIndex]} 1x, ${laptopImage[currentImageIndex]} 2x`}
    />
    <img
      srcSet={`${images[currentImageIndex]} 1x, ${images[currentImageIndex]} 2x`}
      src={images[currentImageIndex]} 
      alt="terrace garden page"
      className="background-imageNW"
      loading="lazy" 
    />
  </picture>
</div>
          {/* gap */}
          <div className="gap2"></div>

          <div id="Fives">
          <h4 className='mb-6'  >Terrace Garden </h4>
          <h5 className='paraof' >Welcome to our exclusive Terrace Garden setups, featuring large, durable, and aesthetically pleasing pots, along with lightweight potting soil that's even lighter than traditional soil. Enjoy the added convenience of <span style={divStyle}>Free</span> installation directly on your Terrace ＆ Balcony  by our expert team. </h5>
          <Suspense fallback={<div>Loading...</div>}>
          <Bestseller 
            bestlink1='/product/6497fe656346e802c1c70975'
            bestlink2='/product/64e33f33f0d3f225b89e766b'
            bestlink3='/product/6499c73d6346e802c1c70a2e'
            bestlink4='/product/6499cd256346e802c1c70a65'
            pricename = 'pricename'
            underline1 =  'Flowers setup of 5 potted plant in 14 inches [20ltr] pot'
            underline2 =  'Fruit trees set of 14-24inches 5 potted plants set'
            underline3 =  'Pooja plants set of 5 potted plant in 14 inches [20ltr] pot'
            underline4 =  'Palms five set of 5 potted plant in 14 inches [20ltr] pot'

              title1="Flower Five" 
              title2="Fruit Five"
              title3="Pooja Five"
              title4="Palm Five"
           
              image1={fruitsmall1} alt1='Flower plants'
              image2={fruitsmall2}alt2='Fruit Plants'
              image3={fruitsmall3} alt3='Pooja plants'
              image4={fruitsmall4} 
             alt4='Raphis Palm'
              priceofprod1='₹1999/-'
              priceofprod2='₹2699/-'
              priceofprod3='₹1499/-'
              priceofprod4='₹1599/-'
         ratingno1='(19)'
         ratingno2='(7)'
         ratingno3='(2)'
         ratingno4='(7)'
         ratingComponent={ratingComponent}
         button={button1}

         realprice1='₹2499'
         realprice2='₹3999'
         realprice3='₹2099'
         realprice4='2199'
            /></Suspense>
        </div>
 
     {/* gap */}
     <div className="gap2"></div>

<div id="Fives">

<Suspense fallback={<div>Loading...</div>}>
<Bestseller 
  bestlink1='/product/645e62feceeeef0818b43414'
  bestlink2='/product/6499cb3d6346e802c1c70a4b'
  bestlink3='/product/645f27d65a58280b60cb2daa'
  bestlink4='/product/645f50025a58280b60cb2e49'
  pricename = 'pricename'
  underline1 =  'Terrace Forest setup of fruits, bamboo, in large[20inch] 60ltr pots'
  underline2 =  ' 5 low-light plants potted in 14inch [20ltr] lightweight black pot'
  underline3 =  'Balcony Garden Large plants Set potted in  16inch [20ltr] pot'
  underline4 =  'Terrace Flower Garden Setup of 6 Large (20inch) 60ltr pots'
    title1="Terrace Eight" 
    title2="Color Five" 
    title3="Balcony Large"
    title4="Flower 8"
 
    image1={line2img1} alt1='Terrace Garden'
    image2={line2img2} alt2='Ornamental plants'
    image3={line2img3} alt3='Balcony Plants'
    image4={line2img4} alt4='flower plants'
    priceofprod1='₹11186/-'
    priceofprod2='₹1599/-'
    priceofprod3='₹3999/-'
    priceofprod4='₹9999/-'
ratingno1='(9)'
ratingno2='(4)'
ratingno3='(3)'
ratingno4='(1)'
ratingComponent={ratingComponent}
button={button1}
realprice1='₹13186'
realprice2='2199'
realprice3='4999'
realprice4='₹12999'

  /></Suspense>

 
</div>

      

 </div>
   
         
      )}
    </Fragment>
  );
};

export default Terracesets;



