import React, { useEffect, useRef } from "react";


import { Link } from "react-router-dom";
import Announcement from "./Announcement";
import logo from "../../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faBars,
  faMagnifyingGlass,
  faChevronDown,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";

import UserOptionsMy from "./UserOptionsMy";

import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { logout } from "../../../actions/userAction";
import { useDispatch, useSelector } from "react-redux";


const Header = () => {
  // from here to 
  const sliderRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sliderRef.current && !sliderRef.current.contains(event.target)) {
        handleCloseSlider();
      }
    }

    document.body.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.body.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);
  // here
  
  const alert = useAlert();
  const dispatch = useDispatch();

  const routeChange0 = () => {
    let path = `/terracegarden`;
    history.push(path);
  };

  const routeChange1 = () => {
    let path = `/product/6495b302449bd41da0a8bed9`;
    history.push(path);
  };

  const routeChange3 = () => {
    let path = `/forest`;
    history.push(path);
  };

  const routeChange4 = () => {
    let path = `/product/645e62feceeeef0818b43414`;
    history.push(path);
  };
  const routeChange5 = () => {
    let path = `/pots&pottingsoil`;
    history.push(path);
  };
  const routeChange6 = () => {
    let path = `/indoorplants`;
    history.push(path);
  };
  const routeChange7 = () => {
    let path = `/product/6497fe656346e802c1c70975`;
    history.push(path);
  };
  const routeChange8 = () => {
    let path = `/pottedplants`;
    history.push(path);
  };

  const { cartItems } = useSelector((state) => state.cart);

  const { isAuthenticated, user } = useSelector((state) => state.user);
 
// new addde
 function handleClicks(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}


  function logoutUser() {
    dispatch(logout());
    alert.success("Logout Successfully");
  }
// new
const history = useHistory();

const openCategory = (category) => {
  history.push(`/products?category=${category}`);
};
function handleCloseSlider() {
  // Logic to close the slider
  // You can manipulate the DOM or update state as needed
  document.getElementById("close-btn").checked = true;
}
  return (
    <div className="bodyofmenu" ref={sliderRef}>
    
    <Announcement/>
      <nav className="menuWrap">
        <div className="wrapper">
          <div className="logo">
            <Link to="/">
              {" "}
              <img src={logo} alt="Forestmakers__logo" loading="lazy" />
            </Link>{" "}
          </div>

          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul className="nav-links">
            <label for="close-btn" className=" close-btn">
              {" "}
              <FontAwesomeIcon icon={faXmark} />
            </label>
            <li> 
              <Link to="/products"  onClick={() => {  handleCloseSlider(); }} className="desktop-item">
                PRODUCTS <FontAwesomeIcon icon={faChevronDown} />{" "}
              </Link>
              <input type="checkbox" id="showMegas" />
              <label for="showMegas" className="mobile-item">
                Products <FontAwesomeIcon icon={faChevronDown} />
              </label>
              <div className="mega-box">
                <div className="content">
                {/* products list  */}

                <div  className="row"   onClick={() => { openCategory(); handleCloseSlider(); }}  style={{cursor: 'pointer'}} >
                    <header>&nbsp; All-Products</header>
                    <ul className="mega-links">
                   <img
                        src="https://res.cloudinary.com/dc5luxn64/image/upload/v1690561192/Backgrounds/all_products_su7zyd.webp"
                        alt="Potting mix" loading="lazy"
                      />
                    </ul>
                  </div>


                  <div  className="row"  onClick={() => { routeChange8(); handleCloseSlider(); }} style={{cursor: 'pointer'}} >
                    <header>&nbsp; Outdoor-Plants</header>
                    <ul className="mega-links">
                      <img
                        src="https://res.cloudinary.com/dc5luxn64/image/upload/v1692704791/Backgrounds/large_orange_plant_potted_c3yql8.webp"
                        alt="Larged pots" loading="lazy"
                      />
                    </ul>
                  </div>



                  <div  className="row"  onClick={() => { routeChange5(); handleCloseSlider(); }} style={{cursor: 'pointer'}} >
                    <header>&nbsp; Filled-pots & Potting Soil</header>
                    <ul className="mega-links">
                      <img
                        src="https://res.cloudinary.com/dc5luxn64/image/upload/v1683964508/products/aabxmlifcc7nrw2crn6j.webp"
                        alt="Larged pots" loading="lazy"
                      />
                    </ul>
                  </div>

                
               
                  <div className="row" onClick={() => { routeChange0(); handleCloseSlider(); }}  style={{cursor: 'pointer'}}>
                    <header>&nbsp;Terrace Garden Setups</header>
                    <ul className="mega-links">
                  <img
                        src="https://res.cloudinary.com/dc5luxn64/image/upload/v1679206618/products/ekehijf9cmjmams2qpvq.webp"
                        alt="Terrace garden setups" loading="lazy"
                      />
                    </ul>
                  </div>

                  <div className="row" onClick={() =>  { routeChange6(); handleCloseSlider(); }} style={{cursor: 'pointer'}} >
                    <header>&nbsp; Indoor-Plants</header>
                    <ul className="mega-links">
                       <img
                        src="https://res.cloudinary.com/dc5luxn64/image/upload/v1685522317/products/rp2cqsun8cdjtjciftg9.webp"
                        alt="Forest makers Merchandise" loading="lazy"
                      />
                   
                    </ul>
                  </div>
                </div>
              </div>
            </li>

           

            {/* new terrace grden */}

            <li>
              <Link to="/terracegarden" className="desktop-item">
                TERRACE GARDEN <FontAwesomeIcon icon={faChevronDown} />
              </Link>
              <input type="checkbox" id="showMegasx" />
              <label for="showMegasx" className="mobile-item">
                Terrace Garden <FontAwesomeIcon icon={faChevronDown} />
              </label>
              <div className="mega-box">
                <div className="content">
       
                  {/* <div className="row"  style={{cursor: 'pointer'}} >
                    <header >&nbsp; Terrace garden Setups</header>
                    <ul className="mrgbtm mega-links">
                      <li onClick={() => {handleClicks("balconygarden"); handleCloseSlider();}} >Balcony Garden</li>
                      <li onClick={() => {handleClicks("flowergarden"); handleCloseSlider();}}>Flower Garden setup</li>
                      <li onClick={() => {handleClicks("terracegarden"); handleCloseSlider();}}>Rooftop Forest</li>
                      <li  onClick={() => {handleClicks("fruitgarden"); handleCloseSlider();}}>Fruit setup</li>
                     
                    </ul>
                  </div> */}
                <div className="row"  onClick={() => { routeChange0(); handleCloseSlider(); }}   style={{cursor: 'pointer'}}>
                    <header  onClick={() => openCategory('all-mix')}>&nbsp; Shop-All</header>
                    <ul className="mega-links">
                       <img src="https://res.cloudinary.com/dc5luxn64/image/upload/v1685344888/Backgrounds/girl_shasha_with_terrace_plants_av3fsl.webp"
                        alt="Plants & pots & potting soil & terrace garden setups" loading="lazy" />

                        
                    </ul>
                  </div>
                  <div className="row"  onClick={() => { routeChange1(); handleCloseSlider(); }}   style={{cursor: 'pointer'}}>
                    <header  onClick={() => openCategory('all-mix')}>&nbsp; Fruit plant Setup</header>
                    <ul className="mega-links">
                       <img src="https://res.cloudinary.com/dc5luxn64/image/upload/v1688466274/products/mejnayv7nfhcanuuqsh5.webp"
                        alt="Plants & pots & potting soil & terrace garden setups" loading="lazy" />

                        
                    </ul>
                 
                  </div>

                  <div className="row"  onClick={() => { routeChange7(); handleCloseSlider(); }}   style={{cursor: 'pointer'}}>
                    <header  onClick={() => openCategory('all-mix')}>&nbsp; Flower plant Setup</header>
                    <ul className="mega-links">
                       <img src="https://res.cloudinary.com/dc5luxn64/image/upload/v1688464469/products/kzsbxdlxigknlznxitma.webp"
                        alt="Plants & pots & potting soil & terrace garden setups" loading="lazy" />

                        
                    </ul>
                  </div>
                  <div className="row"  onClick={() => { routeChange4(); handleCloseSlider(); }}   style={{cursor: 'pointer'}}>
                    <header  onClick={() => openCategory('all-mix')}>&nbsp; Rooftop Forest setup</header>
                    <ul className="mega-links">
                       <img src="https://res.cloudinary.com/dc5luxn64/image/upload/v1689341400/products/cebq8xrmubvnki14kwpa.webp"
                        alt="Plants & pots & potting soil & terrace garden setups" loading="lazy" />

                        
                    </ul>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <Link to="/forest" className="desktop-item">
                FOREST <FontAwesomeIcon icon={faChevronDown} />
              </Link>
              <input type="checkbox" id="showMega" />
              <label for="showMega" className="mobile-item">
                Forest <FontAwesomeIcon icon={faChevronDown} />{" "}
              </label>
              <div className="mega-box">
                <div className="content">
               
                  <div className="row" onClick={() => { routeChange3(); handleCloseSlider(); }}  style={{cursor: 'pointer'}}>
                   
                    <ul className="mega-links">
                      <img
                        src="https://res.cloudinary.com/dc5luxn64/image/upload/v1678102633/products/flower_forest_db8ni0.webp"
                        alt="flower garden" loading="lazy"
                      />
                      
                    </ul>
                  </div>

                  <div className="row" onClick={() => { routeChange3(); handleCloseSlider(); }} style={{cursor: 'pointer'}}>
                    {/* <header>&nbsp; RARE-PLANTS</header> */}
                    <ul className="mega-links">
                       <img
                        src="https://res.cloudinary.com/dc5luxn64/image/upload/v1678102633/products/native_forest_l5yyov.webp"
                        alt="native forest" loading="lazy"
                      />
                 
                    </ul>
                  </div>

                  <div className="row" onClick={() => { routeChange3(); handleCloseSlider(); }}  style={{cursor: 'pointer'}}>
               
                    <ul className="mega-links">
                  <img
                        src="https://res.cloudinary.com/dc5luxn64/image/upload/v1678102633/products/food_forest_swrtqu.webp"
                        alt="food forest" loading="lazy"
                      />
                 
                    
                    </ul>
                  </div>


                

                </div>
              </div>
            </li>

            <li>
              <Link className="desktop-item" to="/login">
                {" "}
                {isAuthenticated ? (
                  <>
                    My profile&nbsp;
                    <FontAwesomeIcon icon={faChevronDown} />
                  </>
                ) : (
                  "Login/Register"
                )}{" "}
              </Link>
              <input type="checkbox" id="showDrop" />
              <label for="showDrop" className="mobile-item ">
                {isAuthenticated ? (
                  <>
                    My Profile &nbsp;
                    <FontAwesomeIcon icon={faChevronDown} />
                  </>
                ) : (
                  <>
                    {" "}
                    <Link to="/login" style={{ color: "white" }} onClick={() => { handleCloseSlider(); }}>
                      Login{" "}
                    </Link>
                  </>
                )}
              </label>
              <ul className="drop-menu">
                <li>
                  <Link to="/orders">
                    {isAuthenticated ? "MY ORDERS" : null}{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/cart">{isAuthenticated ? "CART" : null} </Link>
                </li>

                <li onClick={logoutUser} style={{ color: "#275a1c" }}>
                  {isAuthenticated ? <Link>LOGOUT</Link> : null}{" "}
                </li>
              </ul>
            </li>

            <li>
              <Link to="/search" className="desktop-item" >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Link>
              <Link to="/cart" className="desktop-item">
              {cartItems.length}<FontAwesomeIcon icon={faCartShopping} />
              </Link>
              <input type="checkbox" id="showMega" />
            
              <Link to="/search" className="mobile-item" onClick={() => {handleCloseSlider(); }}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                &nbsp; Search
              </Link>

             

          
            </li>
            <li>
              <a>{isAuthenticated && <UserOptionsMy user={user} />}</a>
            </li>

          
          </ul>

          <label for="menu-btn" className="btn menu-btn">
            {" "}
            <FontAwesomeIcon icon={faBars} />
          </label>


          <Link to="/cart" className="cart-mobile ">
              {cartItems.length}<FontAwesomeIcon icon={faCartShopping} />
              </Link>
        </div>
      </nav>

     
    </div>
  );
};

export default Header;
